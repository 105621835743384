import React, { useEffect, useState } from 'react';
import { Card, Button } from 'antd';
import counterService from 'services/SidebarCounterService';
import {
  EuroCircleTwoTone,
  ProfileTwoTone,
  CreditCardTwoTone,
  RedoOutlined,
} from '@ant-design/icons';
import utils from 'utils';

const CounterSidebar = ({ isMobile }) => {
  const [balance, setBalance] = useState(null);
  const [account, setAccount] = useState(null);
  const [cards, setCards] = useState(null);

  const fetchSummaryCounter = () => {
    counterService.getCountersSummary().then((res) => {
      setBalance(res.data.availableBalance);
      setAccount(res.data.activeAccountsCount);
      setCards(res.data.openedCardsCount);
    });
  };

  useEffect(
    () => {
      fetchSummaryCounter();
    },
    // eslint-disable-next-line
    [],
  );
  return (
    <>
      <Card className={isMobile ? 'counter-card mobile' : 'counter-card'}>
        <div>
          <h4 className='counter-title'>Company available amount </h4>
          <div className='d-flex align-items-center '>
            <EuroCircleTwoTone />
            <span className='counter-amount ml-2'>
              {utils.formattingBalance(balance)}
            </span>
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-sm-between'>
          <div>
            <h4 className='counter-title'>Active accounts </h4>
            <ProfileTwoTone />
            <span className='counter-amount ml-2'>{account}</span>
          </div>
          <div>
            <h4 className='counter-title'>Active cards</h4>
            <CreditCardTwoTone />
            <span className='counter-amount ml-2'>{cards}</span>
          </div>
        </div>
      </Card>
      <Button
        type='primary'
        className={isMobile ? 'ml-2 mt-2 mobile' : 'ml-2 mt-2'}
        onClick={() => {
          window.location.reload();
        }}
      >
        Reload
        <RedoOutlined twoToneColor='#fff' />
      </Button>
    </>
  );
};

export default CounterSidebar;
