import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const AuthUserFooter = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className='auth-footer-info'>
              <span>Copyright © {new Date().getFullYear()} Firecard.io. All rights reserved.</span>
              <img src='/img/landing-page/visa_logo.svg' alt='visa_logo' />
            </div>
          </Col>
          <Col>
            <div className='auth-footer-links'>
              <Link
                to='/terms'
                className='auth-footer-link'
              >
                <span>Terms & Conditions</span>
              </Link>
              <Link
                to='/privacy'
                className='auth-footer-link'
              >
                <span>Privacy Policy</span>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AuthUserFooter;
