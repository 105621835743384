import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Privacy = () => {
  useEffect(() => {
    const bootsrapStyle = document.querySelector('.bootstrap-link');
    setTimeout(() => {
      if (!bootsrapStyle) {
        document
          .querySelector('head')
          .insertAdjacentHTML(
            'beforeend',
            '<link class="bootstrap-link" rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"  crossorigin="anonymous" />',
          );
      }
    }, 100);
  });
  return (
    <div className='terms-and-privacy'>
      <Container>
        <Row>
          <Col>
            <h1 className='privacy-title'>Privacy Policy</h1>
            <p>
              This Privacy Policy was posted by the EU Company corporation, the
              Firecard.io company, which may be referred to herein as “we”, “us”
              or “our”. The privacy of our users is important to us. It is our
              policy to respect privacy of our users regarding any information
              we may collect through our Site https://firecard.io/ (hereinafter,
              “our Site”). This Policy reflects our intent to comply with the EU
              General Data Protection Regulation (GDPR) standards. Information
              we collect about you
            </p>
            <h3>Information Collected</h3>
            <p>
              Whenever users visit our Site, servers providing functionality for
              our Site may automatically log the standard data provided by user
              web browser. Such data is considered “non-identifying
              information”, as it does not contain anything that is capable of
              identifying any user personally. The standard data may include
              user computer’s IP address, the type of browser used, the pages
              visited, the time and date of visit and the time spent on each
              page of our Site.
            </p>
            <p>
              We collect personal information when users request information
              about our services and products, request delivery of information,
              schedule an event or otherwise voluntarily provide such
              information through our Site or otherwise. This information may
              include any of the following:
              <br />
              - personal contact information, such as name, phone number and
              email address; and
              <br />
              - services and products any particular user may be interested in
              purchasing
              <br />
              Generally, every user will have control over the amount and type
              of information he or she provides to us when using our Site.
            </p>
            <h3>Personal Information</h3>
            <p>We may ask users to provide personal information, such as:</p>
            <ul className='privacy-list'>
              <li>- Name, surname;</li>
              <li>- Phone number; </li>
              <li>- Email address; </li>
              <li>- Company name; </li>
              <li>- Role with the company; </li>
              <li>- Country of residence.</li>
            </ul>
            <h3>Payment Information</h3>
            <p>
              This data is considered “identifying information”, as it can
              personally identify users. We only request personal information
              relevant to providing users with our Service, and only use it to
              help provide or improve this Service.
            </p>
            <h3>How Information Is Collected</h3>
            <p>
              We collect information by lawful means, with user knowledge and
              consent. We also let users know why we are collecting information
              about users and how it will be used. Users are free to refuse our
              request, with the understanding that, if our request is refused,
              we may be unable to provide the refusing user with some of the
              services and functionality available on our Site.
            </p>
            <h3>How Information Is Used</h3>
            <p>
              We may use the collected information, both identifying and
              non-identifying, to understand who our users are, how they use our
              services, and how we may enhance their experience of our Site. We
              do not disclose any such information publicly. <br /> When we use
              or process personal information about users, we do so only as
              necessary to provide services to users (e.g., to meet our
              contractual and legal obligations), or otherwise with user
              consent, to comply with applicable law, or to fulfill other
              legitimate interests. Through our Site, all users are provided
              with the choice of which types of communications users will
              receive from us, and the ability to change those choices whenever
              users want. Some of the more common, specific uses of the
              information provided by users are described below.
              <br /> Providing Services; Fulfilling Contractual Obligations
              <br /> We may use personal information to provide the services
              requested by users or to otherwise perform any contract we may
              have with users, including communicating with users about the
              scope of work, payment or other related topics. When user account
              is set up on our Site or user otherwise agrees to the terms and
              conditions of our Site, a contract is formed between the
              particular user and us. In order to carry out that contract, we
              must process the information provided to us by user. Some of the
              information provided by users may contain personal information,
              and we may use it in order to do any of the following:
              <br />
              - verify user identity for security purposes;
              <br />
              - provide user with suggestions and advice concerning our
              services;
              <br />
              - provide our services, which includes updating, securing and
              troubleshooting, as well as providing support; and/or
              <br />
              - improve, personalize and develop our services.
              <br />
              We will continue to process this information until the contract
              between user and us ends or is terminated by either party under
              the terms of the contract. <br /> Processing of Information
              Subject to User Consent <br />
              Through certain actions when otherwise there is no contractual
              relationship between user and us, such as when, after having
              browsed our Site, user requests us to provide more information
              about our business, including about our services, we may request
              user to provide consent to us in order to process information that
              may be personal information. Wherever practicable, we aim to
              obtain explicit user consent to process personal information.
              Sometimes user might give consent implicitly, such as when user
              sends us a message containing personal information by email to
              which user expects us to reply. <br />
              When we communicate with users about our business, we will use the
              contact information provided to us to discuss technical issues,
              services, and other information of interest to users. We may also
              send users email messages containing useful information and
              promotional information about our business. Except where a
              particular user has consented to our use of user information for a
              specific purpose, we will not use any of user’s information in any
              way that would identify the user personally.
              <br /> We will continue to process user information on this basis
              until user withdraws consent or it can be reasonably assumed that
              user consent is no longer valid. <br />
              Each user may withdraw consent at any time by instructing us using
              the contact information at the end of this Policy, using
              unsubscribe instructions provided in communications, or changing
              user elections within our Site. However, if user does so, he or
              she may no longer be able to use our Site or our services. <br />
              Legally Required Disclosure of Information <br /> We may be
              legally required to disclose user personal information, if such
              disclosure is: <br />
              - required by law, or other legal process;
              <br />
              - necessary to assist law enforcement officials or governmental
              authorities; <br />
              - necessary to investigate violations of or otherwise enforce our
              Terms and Conditions of Use; <br />
              - necessary to protect us from legal action or claims from third
              parties including users; and/or
              <br />
              - necessary to protect the legal rights, personal/real property,
              or personal safety of our company, customers, third party
              partners, employees, and affiliates. <br />
              Other Legitimate Interest <br /> We may process information or use
              it to communicate with users on the basis that doing so is
              necessary to achieve a legitimate interest, either to a particular
              user or to us. Where we use user information on this basis, we do
              so after having given careful consideration to:
              <br />
              - whether the same objective could be achieved through other
              means; <br />
              - whether processing (or not processing) might cause a particular
              user any harm; <br />
              - whether the user would expect us to process his or her data, and
              whether the user would consider it reasonable to do so;
              <br />
              For example, we may process user data or communicate with the user
              for any of the following purposes:
              <br />
              - operating our business, which includes analyzing our
              performance, meeting our legal obligations, developing our
              workforce, and conducting research; <br />
              - record-keeping for the proper and necessary administration of
              our business; <br />
              - responding to user complaints or service requests; <br />
              - protecting and asserting the legal rights of any party;
              <br />
              - insuring against or obtaining professional advice that is
              required to manage business risk; and/or <br />
              - protecting the interests of any particular user where we believe
              we have a duty to do so. <br />
              We will continue to use this information for this purpose until
              the legitimate interest no longer exists or such use is not
              necessary to achieve the legitimate interest.
            </p>
            <h3>Data Processing and Data Storage</h3>
            <p>
              The personal information we collect is stored and processed in the
              EU. We only transfer user data within jurisdictions subject to
              data protection laws that reflect our commitment to protecting the
              privacy of our users.
              <br /> We only retain personal information for as long as
              necessary to provide our services. While we retain personal
              information, we will protect it by applying commercially
              acceptable means to prevent loss or theft, as well as unauthorized
              access, disclosure, copying, use or modification. That having been
              said, we would like to remind users that no method of electronic
              transmission or storage is 100% secure, and we cannot guarantee
              absolute security of personal information. Whenever users request
              us to delete their personal information, or where user personal
              information is no longer relevant to our operations, we will erase
              it from our system within a reasonable timeframe.
            </p>
            <h3>Use of Cookies</h3>
            <p>
              Like many other websites, we use “Cookies.” A Cookie is a small
              piece of data stored on user computer or mobile device by user’s
              web browser. We may automatically record information when users
              visit our Site, including the URL, IP address, browser type and
              language, visitor activity on our Site, details of the websites
              visited before or after users visit our Site, pages viewed and
              activities undertaken whilst using our services, the date and time
              of user visit. We use this information to analyze trends among our
              users to help improve our Site or customize communications and
              information that users receive from us. We also use cookies to
              enhance user online experience by eliminating the need to log in
              multiple times for specific content. <br /> We use “cookies” to
              collect information about our users and user activity on our Site.
              A cookie is a small file that our Site stores on user computers,
              and accesses each time user visits, so we can understand how user
              uses our Site. This helps us serve users content based on
              preferences they have specified. Please refer to our Cookie Policy
              for more information. <br /> Each user of our Site may refuse the
              use of cookies by selecting the appropriate settings on his or her
              browser, however users who do this may not be able to use the full
              functionality of our Site. By using our Site, users consent to the
              processing of data about them by Google in the manner and for the
              purposes set out above. <br /> To provide visitors of our Site
              with the ability to prevent their data from being used by Google
              Analytics, Google has developed the Google Analytics opt-out
              browser add-on. Users who wish to opt-out should download and
              install the add-on for their web browser. The Google Analytics
              opt-out add-on is designed to be compatible with Chrome, Safari,
              Firefox and Microsoft Edge. In order to function, the opt-out
              add-on must be able to load and execute properly on user browser.
            </p>
            <h3>Payment Processing</h3>
            <p>
              Payment processing services may personal information of our users
              solely for the purpose of performing specific tasks on our behalf.
              We do not share any personally identifying information with them
              without explicit consent of our users. We do not give payment
              processors permission to disclose or use any of our data for any
              other purpose.
              <br /> We will refuse government and law enforcement requests for
              personal information if we believe a request is too broad or
              unrelated to its stated purpose. However, we may decide to
              cooperate if we believe the requested information is necessary and
              appropriate to comply with legal process, to protect our own
              rights and property, to protect the safety of the public and any
              person, to prevent a crime, or to prevent what we reasonably
              believe to be illegal, legally actionable, or unethical activity.
              <br />
              We do not otherwise share or supply personal information to third
              parties. We do not sell or rent personal information of our users
              to marketers or third parties.
            </p>
            <h3>Third Party Sites</h3>
            <p>
              This Policy only relates to our Site and does not extend to user
              activity on the Internet outside our Site; in particular, it does
              not relate to any websites to which users may link using the
              hypertext connections within our Site and we are not responsible
              for the privacy practices of other websites. <br />
              Users are encouraged to read the privacy policies or privacy
              statements of the other websites from which they visit our Site to
              be aware of their data privacy practices.
            </p>
            <h3>Requesting, Amending or Deleting Personal Information</h3>
            <p>
              Access to user personal information <br />
              Any user may review certain information that we hold about him or
              her by signing in to user account on our Site. To obtain a copy of
              all information we maintain about a particular user, that user may
              send us a request using the contact information at the end of this
              Policy. After receiving user request, we will tell when we expect
              to provide the user with the requested information.
              <br /> Removal or change of user information <br />
              If a user wishes us to remove or change personal information that
              he or she has provided us, the user may contact us at the contact
              information at the end of this Policy. However, the removal or
              change of user information may limit the service we can provide.
              <br /> Verification of information <br />
              When we receive any request to access, edit or delete personal
              information, we will first take reasonable steps to verify user
              identity before granting access or otherwise taking any action.
              This is important to safeguard user information.
              <br /> Retention period for personal data <br />
              Except as otherwise mentioned in this Policy, we keep user
              personal information only for as long as required by us:
              <br />
              - to provide a particular user with the services he or she has
              requested, or otherwise to perform or enforce our contract;
              <br /> - to continue to provide the best possible user experience
              to visitors who return to our Site to collect information;
              <br /> - to comply with other law, including for any period
              demanded by tax authorities; and/or
              <br /> - to support a claim or defense in any court or in any
              legal, regulatory or administrative proceeding.
            </p>
            <h3>Security</h3>
            <p>
              We have implemented appropriate technical and organizational
              security measures to protect the personal information of our users
              in our care, both during transmission and once we receive it. This
              includes physical and technical security measures to protect our
              data from accidental or unlawful destruction, loss, or alteration,
              and from unauthorized disclosure or access. However, users should
              be aware that no method of transmitting information over the
              Internet or storing information is completely secure.
            </p>
            <h3>Limits of this Policy</h3>
            <p>
              This privacy policy only covers our own collecting and handling of
              data. We only work with partners, affiliates and third-party
              providers whose privacy policies align with ours, however we
              cannot accept responsibility or liability for their respective
              privacy practices.
              <br />
              Our Site may link to external sites that are not operated by us.
              Please be aware that we have no control over the content and
              policies of those sites, and cannot accept responsibility or
              liability for their respective privacy practices.
            </p>
            <h3>Changes to this Policy</h3>
            <p>
              At our discretion, we may change our privacy policy to reflect
              current acceptable practices. We will take reasonable steps to let
              users know about changes via our Site. Continued use by users of
              our Site after any changes to this policy will be regarded as
              acceptance of our practices around privacy and personal
              information.
            </p>
            <h3>User Rights and Responsibilities</h3>
            <p>
              As our user, you have the right to be informed about how your data
              is collected and used. You are entitled to know what data we
              collect about you, and how it is processed. You are entitled to
              correct and update any personal information about you, and to
              request that such information be deleted. You may amend or remove
              your account information at any time, using the tools provided in
              your account control panel.
              <br /> You are entitled to restrict or object to our use of your
              data, while retaining the right to use your personal information
              for your own purposes. <br /> Please feel free to contact us if
              you have any concerns or questions about how we handle your data
              and personal information.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Privacy;
