import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Info from './Info';
import User from './User';
import Accounts from './Accounts';
import ID from './ID';
import Company from './Company';
import PricingPlans from './PricingPlan';
import Onboarding from './Onboarding';

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  info: Info,
  user: User,
  accounts: Accounts,
  ID: ID,
  company: Company,
  pricingPlans: PricingPlans,
  onboarding: Onboarding,
});

export default reducers;
