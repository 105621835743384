import React from 'react';
import Flex from 'components/shared-components/Flex';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import utils from 'utils';

export const AuthUserHeader = ({ firstName, lastName, role }) => {
  return (
    <Flex className='header-container container justify-content-between align-items-center'>
      <div>
        <NavLink to='/'>
          <img src='img/logo_landing.svg' alt='logo' />
        </NavLink>
      </div>

      <div className='list-container'>
        <div className='align-items-end flex-column d-flex'>
          <h5>{`${firstName} ${lastName}`}</h5>
          <span>{utils.getUserRole(role)}</span>
        </div>
      </div>
    </Flex>
  );
};

const mapStateToProps = ({ user }) => {
  const { firstName, lastName, role } = user;
  return {  firstName, lastName, role };
};

export default connect(mapStateToProps)(AuthUserHeader);
