import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ContactUs from './Contact-Us';

export const PricingPage = () => {
  return (
    <>
      <Container className='pricing-plan'>
        <Row className='pricing-plan-row'>
          <Col lg={6} sm={12}>
            <h1 className='pricing-title'>pricing</h1>
            <p className='pricing-plan-text'>
              <span className='pricing-plan-text_color'>We will help you</span>{' '}
              to quickly scale up without much effort and safely for your
              business
            </p>
            <div className='table-container'>
              <div className='pricing-table'>
                <p className='pricing-table_title'>For top up</p>
                <div className='table-wrapper'>
                  <div className='table-row'>
                    <span className='table-col issue'>Card issue</span>
                    <span className='table-col_color'>10 Eur</span>
                  </div>
                  <div className='table-row'>
                    <span className='table-col service'>Card service</span>
                    <span className='table-col_color'>0 Eur/mo</span>
                  </div>
                  <div className='table-row'>
                    <span className='table-col topup'>Top up, comission</span>
                    <span className='table-col_color'> 5 %</span>
                  </div>
                  <div className='table-row'>
                    <span className='table-col payment'>
                      Payment system commission, international bank transfer
                    </span>
                    <span className='table-col_color'>5% </span>
                  </div>
                  <div className='table-row'>
                    <span className='table-col crypto'>Crypto deposit commission</span>
                    <span className='table-col_color'>5%</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ContactUs />
    </>
  );
};

export default PricingPage;
