import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { AUTH_TOKEN, SIGNIN, SIGNOUT } from '../constants/Auth';
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
} from '../actions/Auth';
import { saveUserToStore } from '../actions/User';

import { hideLoading } from '../actions/Info';

import JwtAuthService from 'services/JwtAuthService';

export function* signInWithEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    try {
      const user = yield call(JwtAuthService.signIn, payload);
      const { lastName, firstName, id, email } = user.data.user;
      const [role] = user.data.user.roles;
      const { token } = user.data;

      if (user.data.message) {
        yield put(showAuthMessage(user.message));
        yield put(hideLoading());
      } else {
        localStorage.setItem(AUTH_TOKEN, token);
        yield put(authenticated(token));
        yield put(saveUserToStore({ firstName, lastName, role, id, email}));
        yield put(hideLoading());
      }
    } catch (err) {
      yield put(showAuthMessage(err.data.errors.detail));
      yield put(hideLoading());
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      localStorage.removeItem(AUTH_TOKEN);
      yield put(signOutSuccess());
      yield put(hideLoading());
    } catch (err) {
      yield put(showAuthMessage(err.data.errors.detail));
      yield put(hideLoading());
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signInWithEmail), fork(signOut)]);
}
