import fetch from 'FetchInterseptor/general/FetchInterceptor';

const counterService = {};

counterService.getCountersSummary = function () {
  return fetch({
    url: `/counters/summary`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
  });
};

export default counterService;
