import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover='content' />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./users`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/companies`}
          component={lazy(() => import(`./companies`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/accounts`}
          component={lazy(() => import(`./accounts`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cards`}
          component={lazy(() => import(`./cards`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/statement`}
          component={lazy(() => import(`./statement`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/accounts`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
