import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';

export const Footer = () => {
  return (
    <div className='footer-section'>
      <Container>
        <Row>
          <Col lg={4} sm={12} md={4}>
            <Link to='/'>
              <img src='/img/landing-page/white_logo.png' alt='white_logo' />
            </Link>
          </Col>
          <Col lg={4} md={4} sm={12} className='contact-us-col'>
            <Link
              to={`${AUTH_PREFIX_PATH}/contact-us`}
              className='contact-us-link'
            >
              Contact US
            </Link>
          </Col>
          <Col lg={4} md={4} sm={12} className='mail-col'>
            <a href='mailto:support@firecard.io' className='mail-link'>
              support@firecard.io
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='footer-info'>
              <span>Copyright © {new Date().getFullYear()} Firecard.io. All rights reserved.</span>
              <img src='/img/landing-page/visa_logo.svg' alt='visa_logo' />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
