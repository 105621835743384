import {
  GET_ACCOUNT_LIST,
  GET_SUBACCOUNTS_LIST,
  CLEAR_SUBACCOUNTS_LIST,
} from '../constants/Accounts';

const initialState = {
  account: [],
  subaccount: [],
};
const accounts = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_LIST: {
      return {
        ...state,
        account: action.accounts,
      };
    }
    case GET_SUBACCOUNTS_LIST: {
      return {
        ...state,
        subaccount: action.subaccounts,
      };
    }
    case CLEAR_SUBACCOUNTS_LIST: {
      return { ...state, subaccount: [] };
    }
    default:
      return state;
  }
};

export default accounts;
