import React from 'react';
import Header from 'components/layout-components/LandingPage/Header';
import Footer from 'components/layout-components/LandingPage/Footer';
import Terms from '../component/Terms';
import AuthUserFooter from '../component/Footer';
import AuthUserHeader from '../component/Header'

const TermsPage = () => {
  const token = !!localStorage.getItem('auth_token');

  return (
    <>
    {token ? <AuthUserHeader/> : <Header/>}
      <Terms />
      {token ? <AuthUserFooter /> : <Footer />}
    </>
  );
};

export default TermsPage;
