import React from 'react';
import Header from 'components/layout-components/LandingPage/Header';
import Footer from 'components/layout-components/LandingPage/Footer';
import Privacy from '../component/Privacy';
import AuthUserFooter from '../component/Footer';
import AuthUserHeader from '../component/Header'

const PrivacyPage = () => {
  const token = !!localStorage.getItem('auth_token');

  return (
    <>
    {token ? <AuthUserHeader/> : <Header/>}
      <Privacy />
      {token ? <AuthUserFooter /> : <Footer />}
    </>
  );
};

export default PrivacyPage;
