import fetch from 'FetchInterseptor/auth/FetchInterceptor';
import { API_AUTHORIZATION_TOKEN } from '../constants/ApiConstant';

const ContactUsService = {};

ContactUsService.contactUs = function (data) {
  return fetch({
    url: '/contact-us',
    method: 'post',
    headers: {
      'public-request': 'true',
      Authorization: `Basic ${API_AUTHORIZATION_TOKEN}`,
    },
    data: data,
  });
};

ContactUsService.contactUsRequestAccess = function (data) {
  return fetch({
    url: '/contact-us/request-access',
    method: 'post',
    headers: {
      'public-request': 'true',
      Authorization: `Basic ${API_AUTHORIZATION_TOKEN}`,
    },
    data: data,
  });
};
export default ContactUsService;
