import React, { useRef } from 'react';
import Flex from 'components/shared-components/Flex';
import { NavLink } from 'react-router-dom';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';
import { Button } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { signOut } from 'redux/actions/Auth';

export const Header = ({ token, signOut, match }) => {
  const hamburgerRef = useRef(null);
  const mobileNavListRef = useRef(null);

  const addActiveClass = () => {
    const hamburgerClassList = hamburgerRef.current.classList;
    const mobileNavLisClassList = mobileNavListRef.current.classList;
    if (!hamburgerClassList.contains('active')) {
      hamburgerClassList.add('active');
      mobileNavLisClassList.add('visible');
    } else {
      hamburgerClassList.remove('active');
      mobileNavLisClassList.remove('visible');
    }
  };
  const scrollToContactUsSection = () => {
    document
      .querySelector('#contact-us')
      .scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Flex className='header-container container justify-content-between align-items-center'>
      <div>
        <NavLink to='/'>
          <img src='img/logo_landing.svg' alt='logo' />
        </NavLink>
      </div>

      <div className='list-container'>
        <ul className='nav-landing d-flex  flex-row justify-content-between align-items-center'>
          <li className='nav-item'>
            <NavLink to='/pricing'>Pricing</NavLink>
          </li>
          <li className='nav-item' onClick={scrollToContactUsSection}>
            <NavLink to='#contact-us'>Contact us</NavLink>
          </li>
          <li className='nav-item'>
            {!token ? (
              <NavLink to={`${AUTH_PREFIX_PATH}/login`} className='login-link'>
                <Button type='primary' className='login-btn'>
                  Log in
                  <LoginOutlined />
                </Button>
              </NavLink>
            ) : (
              <Button
                type='primary'
                className='login-btn'
                onClick={() => signOut()}
              >
                Log out
                <LoginOutlined />
              </Button>
            )}
          </li>
        </ul>
        <div ref={hamburgerRef} className='hamburger' onClick={addActiveClass}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div ref={mobileNavListRef} className='mobile-nav '>
        <ul className='d-flex mobile-naw-list flex-column justify-content-between align-items-center'>
          <li className='nav-item'>
            <NavLink to='/pricing'>Pricing</NavLink>
          </li>
          <li className='nav-item'>
            <NavLink to='#contact-us'>Contact us</NavLink>
          </li>
          <li className='nav-item'>
            {!token ? (
              <NavLink to={`${AUTH_PREFIX_PATH}/login`} className='login-link'>
                <Button type='primary' className='login-btn'>
                  Log in
                  <LoginOutlined />
                </Button>
              </NavLink>
            ) : (
              <Button
                type='primary'
                className='login-btn'
                onClick={() => signOut()}
              >
                Log out
                <LoginOutlined />
              </Button>
            )}
          </li>
        </ul>
      </div>
    </Flex>
  );
};

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default connect(mapStateToProps, { signOut })(Header);
