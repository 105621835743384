import React, { useEffect } from 'react';
import Icon from '@ant-design/icons';
import { Arrow } from 'assets/svg/icon';
import { Button, Form, Input, notification } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import ContactUsService from 'services/ContactUsService';
import utils from 'utils';

const ArrowBtn = (props) => <Icon component={Arrow} {...props} />;

export const ContactUs = ({ showMessage, hideMessage }) => {
  const [form] = Form.useForm();

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: 'This field is required!',
    types: {
      email: 'This is not a valid email!',
    },
    pattern: {
      mismatch: 'Phone is not a valid. Should be as +12456789787',
    },
  };

  const sendContactUsRequest = (values) => {
    ContactUsService.contactUs(values)
      .then((resp) => {
        if (200 === resp.status) {
          notification.success(showMessage('Your e-mail was send!'));
          form.resetFields();
        }
      })
      .catch((error) => showMessage(error.data.errors.detail));
  };

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        hideMessage();
      }, 3000);
    }
  });
  return (
    <Container id='contact-us' className='contact-us-section'>
      <Row>
        <Col lg={6} sm={12} className='contact-us-section-title'>
          <p>let`s</p>
          <p>get in</p>
          <p>touch</p>
        </Col>
        <Col lg={6} sm={12}>
          <Form
            layout='vertical'
            name='contact-us'
            onFinish={sendContactUsRequest}
            validateMessages={validateMessages}
            form={form}
          >
            <Form.Item
              name='fullName'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder='Full name' className='contact-us-input' />
            </Form.Item>
            <Form.Item
              name='emailForReply'
              rules={[{ required: true, type: 'email' }]}
            >
              <Input placeholder='Email' className='contact-us-input' />
            </Form.Item>
            <Form.Item
              name='phone'
              rules={[
                {
                  required: true,
                  // eslint-disable-next-line
                  pattern: /^(\+)?[0-9]{8,15}$/,
                },
              ]}
            >
              <Input
                placeholder='Phone'
                autoComplete='off'
                className='contact-us-input'
                type='tel'
                style={{
                  width: '100%',
                }}
                onInput={utils.deleteNonNumberValue}
              />
            </Form.Item>
            <Form.Item
              name='message'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea
                placeholder='Message'
                className='contact-us-input'
              />
            </Form.Item>
            <Form.Item className='arrow-btn'>
              <Button
                type='default'
                htmlType='submit'
                block
                className='contact-us-arrowbtn'
                icon={<ArrowBtn className='arrow-svg' />}
              ></Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
export default ContactUs;
