import { HIDE_MESSAGE, SHOW_LOADING, SHOW_MESSAGE, HIDE_LOADING } from "../constants/Info";

const initialState = {
  loading: false,
  showMessage: false,
  message: '',
};

const info = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case HIDE_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case SHOW_MESSAGE: {
        return {
          ...state,
          showMessage: true,
          message:action.message,
          loading: false
        };
      }
      case HIDE_MESSAGE: {
        return {
          ...state,
          showMessage: false,
          message:''
        };
      }
    default:
      return state;
  }
};
export default info;