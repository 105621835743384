import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Divider } from 'antd';
import Flex from 'components/shared-components/Flex';
import { Link } from 'react-router-dom';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';
import { Button, Form, Input, notification } from 'antd';
import ContactUsService from 'services/ContactUsService';
import { showMessage, hideMessage } from 'redux/actions/Info';
import { connect } from 'react-redux';
import ContactUs from './Contact-Us';

export const Content = ({ showMessage, hideMessage }) => {
  const [sendForm] = Form.useForm();

  const addOpenClass = (event) => {
    const FAQClassList = event.target.parentElement.classList;

    if (!FAQClassList.contains('active')) {
      FAQClassList.add('active');
    } else {
      FAQClassList.remove('active');
    }
  };

  const sendContactUsRequestAccess = (values) => {
    ContactUsService.contactUsRequestAccess(values)
      .then((resp) => {
        if (200 === resp.status) {
          notification.success(showMessage('Your e-mail was send!'));
          sendForm.resetFields();
        }
      })
      .catch((error) => showMessage(error.data.errors.detail));
  };

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        hideMessage();
      }, 3000);
    }
  });

  useEffect(() => {
    document.querySelector('.promo-section').onmousemove = function (event) {
      document.querySelector(
        '.animation_img_tiktok',
      ).style.transform = `translate3d(${
        (event.clientX / window.innerWidth) * 50
      }px, ${(-event.clientY / window.innerHeight) * 50}px, 0px)`;
      document.querySelector(
        '.animation_img_google',
      ).style.transform = `translate3d(${
        (-event.clientX / window.innerWidth) * 50
      }px, ${(event.clientY / window.innerHeight) * 50}px, 0px)`;
      document.querySelector(
        '.animation_img_facebook',
      ).style.transform = `translate3d(${
        (-event.clientX / window.innerWidth) * 50
      }px, ${(event.clientY / window.innerHeight) * 50}px, 0px)`;
      document.querySelector(
        '.animation_img_apple',
      ).style.transform = `translate3d(${
        (event.clientX / window.innerWidth) * 50
      }px, ${(-event.clientY / window.innerHeight) * 50}px, 0px)`;
    };
  });

  return (
    <>
      <Container className='promo-section'>
        <Row>
          <Col lg={6} sm={12} className='promo-info'>
            <h1 className='promo-title'>
              <span className='title-color-text'>Сard issuing</span> for media
              buying and <b>digital</b> marketing
            </h1>
            <p className='promo-subtitle'>
              Create your own virtual cards right now in unlimited quantities.
            </p>
            <Form
              className='send-email-form'
              onFinish={sendContactUsRequestAccess}
              form={sendForm}
            >
              <Form.Item
                name='email'
                rules={[{ required: true, type: 'email' }]}
              >
                <Input placeholder='Your email' className='promo-input' />
              </Form.Item>
              <Button type='primary' htmlType='submit' className='promo-btn'>
                Get a card
              </Button>
            </Form>
          </Col>
          <Col lg={6} sm={12} className='animation-block'>
            <div className='animation_img_wrapper'>
              <div className='animation_img_tiktok'>
                <img src='/img/landing-page/tiktok.png' alt='tiktok' />
              </div>
            </div>
            <div className='animation_img_wrapper'>
              <div className='animation_img_google'>
                <img src='/img/landing-page/google.png' alt='google' />
              </div>
            </div>
            <div className='animation_img_wrapper'>
              <div className='animation_img_facebook'>
                <img src='/img/landing-page/facebook.png' alt='facebook' />
              </div>
            </div>
            <div className='animation_img_wrapper'>
              <div className='animation_img_apple'>
                <img src='/img/landing-page/apple.png' alt='apple' />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className='benefit-section'>
        <Row>
          <Col lg={6} sm={12} className='benefit-reason'>
            <h3 className='benefit-reason-title d-flex align-items-center'>
              Why Firecard?
            </h3>
            <p className='benefit-reason-text'>
              We will help you to quickly scale up without much effort and
              safely for your business
            </p>
          </Col>
          <Col lg={6} sm={12} className='benefit-amounts'>
            <Row>
              <Col lg={6} sm={12} className='benefit-amounts-col'>
                <p className='benefit-amount'>5%</p>
                <p className='benefit-amount-text'>Replenishment commission</p>
              </Col>
              <Col lg={6} sm={12} className='benefit-amounts-col'>
                <p className='benefit-amount card'>0€ / mo</p>
                <p className='benefit-amount-text'>Card service</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider className='divider' />

        <h2 className='benefit-list-title'>
          Benefits of using <span className='title-color-text'>Firecard</span>
        </h2>
        <div className='benefit-list'>
          <Row>
            <Col lg={6} sm={12} className='benefit-first-col'>
                <Flex className='flex-column'>
                  <h3 className='d-flex align-items-center optimization'>
                    Optimization
                  </h3>
                  <p className='benefit-reason-text'>
                    Cards optimized for Facebook, Google, TikTok and other
                    advertising platforms
                  </p>
                </Flex>
                <Flex className='flex-column'>
                  <h3 className='d-flex align-items-center safety'>Safety</h3>
                  <p className='benefit-reason-text'>
                    Anonymous corporate payments, Visa credit cards
                  </p>
                </Flex>
                <Flex className='flex-column'>
                  <h3 className='d-flex align-items-center rates'>
                    Good rates
                  </h3>
                  <p className='benefit-reason-text'>
                    Good rates and top-up with Crypto or Bank transfers
                  </p>
                </Flex>
              <div className='benefit-img'>
                <picture>
                  <source
                    media='(max-width: 457px)'
                    alt='girl'
                    srcSet='/img/landing-page/Illustration_boy.png'
                  ></source>
                  <source
                    media='(max-width: 1400px)'
                    alt='girl'
                    srcSet='/img/landing-page/Illustration_girl.png'
                  ></source>
                  <img
                    src='/img/landing-page/Illustration_girl.png'
                    alt='girl'
                  />
                </picture>
              </div>
            </Col>
            <Col lg={6} sm={12} className='benefit-second-col'>
              <div className='benefit-img'>
                <picture>
                  <source
                    media='(max-width: 457px)'
                    alt='girl'
                    srcSet='/img/landing-page/Illustration_girl.png'
                  ></source>
                  <source
                    media='(max-width: 1400px)'
                    alt='girl'
                    srcSet='/img/landing-page/Illustration_boy.png'
                  ></source>

                  <img src='/img/landing-page/Illustration_boy.png' alt='boy' />
                </picture>
              </div>
                <Flex className='flex-column'>
                  <h3 className='d-flex align-items-center unlimited'>
                    Unlimited
                  </h3>
                  <p className='benefit-reason-text'>
                    Unlimited cards and unlimited spend
                  </p>
                </Flex>
                <Flex className='flex-column'>
                  <h3 className='d-flex align-items-center cards'>Cards</h3>
                  <p className='benefit-reason-text'>
                    Fast ​​and convenient opening of new cards
                  </p>
                </Flex>
                <Flex className='flex-column'>
                  <h3 className='d-flex align-items-center account'>Account</h3>
                  <p className='benefit-reason-text'>
                    Create your own team or work on your own, distribute cards
                    and budget for participants.
                  </p>
                </Flex>
            </Col>
          </Row>
        </div>
      </Container>
      <Container className='faq-section'>
        <Row>
          <Col lg={3} sm={12}>
            <h2 className='faq-title'>FAQ`s</h2>
          </Col>
          <Col lg={9} sm={12} onClick={addOpenClass}>
            <div className='faq-block'>
              <h3 className='faq-block-title'>How to top up an account?</h3>
              <p className='faq-text'>
                Crypto deposit or international wire transfer are avaiable You
                can request replenishment through the Request top up form from
                our managers.
              </p>
            </div>
            <div className='faq-block'>
              <h3 className='faq-block-title'>
                Do you work with teams/solo arbitrage specialists?
              </h3>
              <p className='faq-text'>
                You can use your account both solo and as a team. Create teams
                and work together, a team account includes two types of users: -
                AdminAccount - User (account created by administrator) Use it
                individually, account is suitable for a user who independently
                manages his balance and cards.
              </p>
            </div>
            <div className='faq-block'>
              <h3 className='faq-block-title'>
                How long does it take to issue a card?
              </h3>
              <p className='faq-text'>The card is issued in seconds.</p>
            </div>
            <div className='faq-block'>
              <h3 className='faq-block-title'>
                Does the card have 3DS (3D Secure)?
              </h3>
              <p className='faq-text'>
                Yes, you can issue cards with 3DS (3D Secure).
              </p>
            </div>
            <div className='faq-btn'>
              <Link to={`${AUTH_PREFIX_PATH}/login`} className='open-card-link'>
                <Button type='primary' className='open-card-btn'>
                  Open card
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <ContactUs showMessage={showMessage} hideMessage={hideMessage} />
      <Container className='support-section'>
        <Row>
          <Col lg={6} sm={12} className='support-section-col'>
            <span className='time-support'>24/7 support</span>
            <span className='time-support-text'>
              We will help you to quickly scale up{' '}
            </span>
          </Col>
          <Col lg={6} sm={12} className='support-section-col'>
            <Link to={`${AUTH_PREFIX_PATH}/login`} className='open-card-link'>
              <Button type='primary' className='open-card-btn'>
                Open card
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};
const mapStateToProps = ({ info }) => {
  const { showMessage } = info;
  return { showMessage };
};
const mapDispatchToProps = {
  showMessage,
  hideMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
