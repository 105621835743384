import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  FETCH_ACCOUNTS_LIST,
  FETCH_SUBACCOUNTS_LIST,
} from '../constants/Accounts';
import accountService from 'services/AccountService';
import { getAccountList, getSubaccountList } from '../actions/Accounts';
import { showMessage } from '../actions/Info';

export function* getAccountsList() {
  yield takeEvery(FETCH_ACCOUNTS_LIST, function* ({ payload }) {
    try {
      const getAccount = yield call(accountService.getAccounts, payload);
      yield put(getAccountList(getAccount.data.data));
    } catch (err) {
      yield put(showMessage(err.data.errors.detail));
    }
  });
}

export function* getSubaccountsList() {
  yield takeEvery(FETCH_SUBACCOUNTS_LIST, function* ({ payload }) {
    try {
      const getSubaccounts = yield call(accountService.getAccounts, payload);
      yield put(getSubaccountList(getSubaccounts.data.data));
    } catch (err) {
      yield put(showMessage(err.data.errors.detail));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getAccountsList), fork(getSubaccountsList)]);
}
