import React, { useEffect } from 'react';
import Header from 'components/layout-components/LandingPage/Header';
import Footer from 'components/layout-components/LandingPage/Footer';
import PricingPage from 'components/layout-components/LandingPage/Pricing-page';

export const Pricing = () => {
  useEffect(() => {
    const bootsrapStyle = document.querySelector('.bootstrap-link');
    setTimeout(() => {
      if (!bootsrapStyle) {
        document
          .querySelector('head')
          .insertAdjacentHTML(
            'beforeend',
            '<link class="bootstrap-link" rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"  crossorigin="anonymous" />',
          );
      }
    }, 100);
  });

  return (
    <div className='pricing-page'>
      <Header />
      <PricingPage />
      <Footer />
    </div>
  );
};

export default Pricing;
