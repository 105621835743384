import { ENABLED_STEPS } from '../constants/Onboarding';

const initState = {
  stepsEnabled: true,
};

const Onboarding = (state = initState, action) => {
  switch (action.type) {
    case ENABLED_STEPS:
      return {
        ...state,
        stepsEnabled: action.stepsEnabled,
      };
    default: {
      return state;
    }
  }
};

export default Onboarding;
