import React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { connect } from 'react-redux';
import {
  LogoutOutlined,
  QuestionCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import { signOut } from 'redux/actions/Auth';
import utils from 'utils';
import Icon from 'components/util-components/Icon';
import { setEnebledStep } from 'redux/actions/Onboarding';

const menuItem = [
  {
    title: 'Help Center',
    icon: QuestionCircleOutlined,
    path: 'mailto:support@firecard.io',
  },
];

export const NavProfile = (props) => {
  const { signOut, firstName, lastName, role, setEnebledStep } = props;

  const profileMenu = (
    <div className='nav-profile nav-dropdown'>
      <div className='nav-profile-header'>
        <div className='d-flex'>
          <Avatar
            size={45}
            style={{ backgroundColor: '#F1B045' }}
          >{`${firstName[0]}${lastName[0]}`}</Avatar>
          <div className='pl-3'>
            <h4 className='mb-0'>{`${firstName} ${lastName}`}</h4>
            <span className='text-muted'>{utils.getUserRole(role)}</span>
          </div>
        </div>
      </div>
      <div className='nav-profile-body'>
        <Menu>
          <Menu.Item
            key='onboarding'
            onClick={() => {
              setEnebledStep(true)
              window.location.reload();
            }}
          >
            <span>
              <RightCircleOutlined />
              <span className='font-weight-normal'>Re-pass Onboarding</span>
            </span>
          </Menu.Item>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon type={el.icon} />
                  <span className='font-weight-normal'>{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key='signOut' onClick={() => signOut()}>
            <span>
              <LogoutOutlined />
              <span className='font-weight-normal'>Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement='bottomRight' overlay={profileMenu} trigger={['click']}>
      <Menu className='d-flex align-item-center' mode='horizontal'>
        <Menu.Item key='profile'>
          <Avatar
            size={45}
            style={{ backgroundColor: '#F1B045' }}
          >{`${firstName[0]}${lastName[0]}`}</Avatar>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ user }) => {
  const { firstName, lastName, role } = user;
  return { firstName, lastName, role };
};

export default connect(mapStateToProps, { signOut, setEnebledStep })(NavProfile);
