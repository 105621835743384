import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Terms = () => {
  useEffect(() => {
    const bootsrapStyle = document.querySelector('.bootstrap-link');
    setTimeout(() => {
      if (!bootsrapStyle) {
        document
          .querySelector('head')
          .insertAdjacentHTML(
            'beforeend',
            '<link class="bootstrap-link" rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"  crossorigin="anonymous" />',
          );
      }
    }, 100);
  });
  return (
    <div className='terms-and-privacy'> 
      <Container>
        <Row>
          <Col>
            <h1 className='privacy-title'>Terms of Use</h1>
            <p>
              Please review these Terms of Use (“Terms”) before using this site
              or accessing any data on it. If you do not agree to these Terms
              without limitation or exclusion, you should exit this Site.
            </p>
            <p>
              Welcome to the https://firecard.io/ advertising spend management
              and transaction facilitation platform, which is operated by the EU
              company corporation (“Company,” “we,” or “us”).
            </p>
            <p>
              By accessing or using our Site at https://firecard.io and any of
              its subdomains (“Site”), you (“User” or “you”) signify that you
              have read, understand, and agree to be bound by these Terms,
              regardless of whether you are a registered member of the Service.
            </p>
            <p>
              We refer to the services provided by or through the Site as the
              “Services”. These Terms set forth the legally binding terms and
              conditions which are applicable to your use of the Services and
              Site. Please be reminded that the Terms constitute an agreement
              between you and us and define the rights and responsibilities that
              you have with respect to the Services and Site. Therefore, we
              encourage you to carefully familiarize yourself with the Terms.
            </p>
            <p>
              By registering on the Site and/or by clicking “I accept” or a
              similar button and/or by installing, accessing or using the Site
              (including any software or application forming part of the
              Services) you confirm that you have read and understood the Terms
              and any other documents referred to herein, including our Privacy
              Policy, and that you agree to be bound by the Terms.
            </p>
            <p>
              You represent and warrant that you have the right, authority and
              capacity to accept these Terms and to abide by them and that you
              have fully read and understood the Terms without any impairment in
              judgment resulting from (but not limited to) mental illness,
              intoxication, medication, or any other health or other problem
              that could impair judgment. By accepting these Terms, you
              authorize us to store payment credentials, and to send payment
              instructions to the issuing bank and take payment (one time or
              recurring) from a user’s credit/debit/bank account or from the
              amounts held by us.
            </p>
            <p>
              In addition to these Terms, you may enter into other agreements
              with us or others that will govern your use of the Service or
              related services offered by us or others. If there is any
              contradiction between these Terms and another agreement that you
              enter into and that is applicable to specific aspects of the
              Service, the other agreement will be controlling.
            </p>

            <h3>Changes to these Terms</h3>
            <p>
              We may make changes to these Terms from time to time. If we do
              this, we will post the changed Terms on the Site and will indicate
              at the top of this page the date the Terms were last revised.
            </p>
            <p>
              You understand and agree that your continued use of the Service
              after we have made any such changes constitutes your acceptance of
              the new Terms.
            </p>
            <p>
              If you do not agree to the modified Terms, you must stop using the
              Services. You can cancel your account with us without further
              obligation.
            </p>
            <h2>OPERATION OF THE SERVICE</h2>
            <h3>Registration Data; Account Security</h3>
            <p>
              In consideration of your use of the Service, you agree to (a)
              provide true, accurate, current and complete information about
              yourself (or, if applicable, the corporate member you represent)
              as prompted by the registration form (“Registration Data”) that
              will help us to identify you. We may also ask to see other
              identifying documents including any related business documents;
              (b) maintain the security of your password and identification; (c)
              maintain and promptly update the Registration Data, and any other
              information you provide to the Company, to keep it accurate,
              current and complete; (d) promptly notify the Company regarding
              any material changes to information or circumstances that could
              affect your eligibility to continue to use the Service or the
              terms on which you use the Service; and (e) be fully responsible
              for all use of your account and for any actions that take place
              using your account.
            </p>
            <p>
              If you are registering the Account for a legal entity, you
              represent and warrant that you have the authority to legally bind
              that entity and grant us all permissions and licenses provided in
              these Terms, including but not limited to the Power of Attorney
              for a person who can act on behalf of such a legal entity and the
              foundation documents.
            </p>
            <p>
              You may not authorize any third party (other than, if applicable,
              the Authorized Users) to access or use our Services on your
              behalf. You agree to keep your access credentials in secure and
              confidential. You must immediately notify us of any unauthorized
              use (or suspected unauthorized use) of your Account access
              credentials. You are responsible for all activity on your Account,
              and for all charges incurred by your Account. The access
              credentials are the property of Company and may be revoked if you
              share them with any third party (other than as allowed in the
              Terms), if they are compromised, or if you violate these Terms.
            </p>
            <h3>Virtual Cards</h3>
            <p>
              Upon User’s request Company will facilitate the issuance of
              virtual cards thought Bank partner and its nonbank affiliates and
              partners (“Cards”) that may be used by Users for commercial
              business purposes subject to these Terms. User will be able to
              track the balance of funds allocated to the Card through Service
              Account. Neither the Account nor a Card account will constitute a
              checking or savings account. User will not receive any interest on
              the funds in such account. To be eligible to receive a Card you
              agree to comply with the Bank partner and its nonbank affiliates
              and partners terms of service and these Terms. The Bank partner
              and its nonbank affiliates and partners terms of service contain
              many important provisions concerning the use of Cards – only some
              of which are summarized in these Terms.
            </p>
            <p>
              You are the owner of all funds accessible through Cards at all
              times and each Authorized User agrees that the Card will only be
              used as authorized by you. You acknowledge and agree that the
              value available in the Account and any Card account is limited to
              the funds that have been allocated to Card account pursuant to
              these Terms and Bank partner and its nonbank affiliates and
              partners terms of service.
            </p>
            <h3>Services</h3>
            <p>
              You expressly agree and consent that Services may only be used for
              payments to social media platforms (including, but not limited to
              Facebook and Instagram) to cover advertisement expenses of User or
              User’s customers.
            </p>
            <p>
              Your Account, Services, and Cards may only be used for your
              business expenses. Your Account, Services, and Cards may not be
              (a) used for any purpose that is unlawful or prohibited by these
              Terms or Bank partner and its nonbank affiliates and partners
              terms of service; (b) used for any personal, family, or household
              use; (c) provided to or used for the benefit of an individual,
              organization, or country that is blocked or sanctioned; (d) used
              by unaffiliated third parties; or (e) used for any other
              activities not for your business benefit.
            </p>
            <p>
              For avoidance of doubt you may not use the Services for the
              following businesses or business activities: (1) any illegal
              activity or goods, (2) buyers or membership clubs, including dues
              associated with such clubs, (3) credit counselling or credit
              repair agencies, (4) credit protection or identity theft
              protection services, (5) internet/mail order/telephone order
              pharmacies or pharmacy referral services (where fulfilment of
              medication is performed with an internet or telephone
              consultation, absent a physical visit, including re-importation of
              pharmaceuticals from foreign countries), (6) prepaid phone cards
              or phone services, (7) betting, including lottery tickets, sports
              related gambling, casino gaming chips, off-track betting, and
              wagers at races, (8) manual or automated cash disbursements, (9)
              prepaid cards, checks, insurance or other financial merchandise or
              services, (10) sales of money-orders or foreign currency, (11)
              high-risk products and services, including telemarketing sales,
              (12) automated fuel dispensers, (13) adult entertainment oriented
              products or services (in any medium, including internet,
              telephone, or printed material), (14) sales of (i) firearms,
              firearm parts or hardware, and ammunition; or (ii)weapons and
              other devices designed to cause physical injury (15) internet/mail
              order/telephone order of age restricted products (e.g., tobacco),
              (16) occult materials, (17) hate or harmful products, (18) escort
              services, or (19) bankruptcy attorneys or collection agencies
              engaged in the collection of debt.
            </p>
            <p>
              You agree to hold harmless and release Company from any liability
              relating to your use of such online services. Your access to and
              conditions of use of such services may be limited in accordance
              with the terms of use published by such third-party provider.
            </p>
            <p>
              If you use the Cards, you agree that you do so at your own risk.
              Unless expressly stated to the contrary, Company does not
              pre-qualify, review, or endorse Bank partner and its nonbank
              affiliates and partners and we provide no representation or
              warranty as to the background, reputation, character,
              qualifications, skills, insurance, work product, services, advice,
              recommendations or quality of the Bank partner and its nonbank
              affiliates and partners. If you use Cards, you acknowledge that
              you are doing so at your own risk and hereby agree to release and
              hold Company harmless from any Claims arising from your use of the
              Cards. Company does not purport to provide you with any financial
              or accounting advice and you agree to release and hold Company
              harmless for and from any Claims (as defined below) arising from
              your use of Services.
            </p>
            <h3>Fees</h3>
            <p>
              You agree to pay the applicable fees disclosed to you when you
              create your Account. All fees are charged when you transferred
              funds to the Company bank account through the wire or crypto. The
              commission fees for use of Service are deducted from the
              transferred or collected funds. We reserve the right to change the
              fees upon reasonable advance notice. All fees, charges, and
              payments collected or paid through the Services are denominated in
              EUR or USD, if it is applicable.
            </p>
            <h2>ELIGIBILITY, LICENSE AND REPRESENTATIONS</h2>
            <h3>Eligibility</h3>
            <p>
              The Service is intended solely for Users who are 18 years of age
              or older, and any registration by, use of or access to the Service
              by any natural person under 18 is unauthorized, unlicensed and in
              violation of these Terms. By using the Service, you represent that
              you are 18 or older and that you agree to and will abide by these
              Terms. If you violate any of these Terms, or otherwise violate any
              agreement between you and us, the Company may terminate your
              membership, delete your profile and any content or information
              that you have posted on the Service or prohibit you from using or
              accessing the Service (or any portion, aspect or feature of the
              Service), at any time in its sole discretion, with or without
              notice, including if it believes that you are under 18.
            </p>
            <h3>Proprietary Rights in Content; Limited License</h3>
            <p>
              All content on the Service, including, but not limited to,
              designs, text, graphics, pictures, video, information, software,
              music, sound and other files, and their selection and arrangement
              (the “Service Content”), are the property of the Company with all
              rights reserved. No Service Content may be modified, copied,
              distributed, framed, reproduced, republished, downloaded,
              displayed, posted, transmitted, or sold in any form or by any
              means, in whole or in part, without the Company’s prior written
              permission, except as provided in the following sentence and
              except that the foregoing does not apply to your own User Content
              (as defined below) that you legally post on the Service. Provided
              that you are eligible to use the Service, you are granted a
              limited license to access and use the Service and to download or
              print a copy of any portion of the Service Content solely for your
              use, provided that you keep all copyright or other proprietary
              notices intact. Except for your own User Content (as defined
              below), you may not republish Service Content on any Internet,
              Intranet or Extranet site or incorporate the information in any
              other database or compilation, and any other use of the Service
              Content is strictly prohibited and will result in the termination
              of the license granted under the Terms. Such unauthorized use may
              also violate applicable laws, such as copyright and trademark laws
              and applicable communications regulations and statutes. This
              license is revocable by us at any time without notice and with or
              without cause.
            </p>
            <h3>Prohibited Activities</h3>
            <p>
              The list below contains examples of behaviours that are prohibited
            </p>
            <ul>
              <li> Impersonating another person or entity;</li>
              <li>
                Accessing or using the Services in an unlawful way or for any
                unlawful purpose;
              </li>
              <li>
                Transmission of any data, materials, content or information
                which is libellous, defamatory, obscene, fraudulent, false or
                contrary to the ownership or intellectual property rights of any
                other person, or otherwise unlawful;
              </li>
              <li>
                Transmission of viruses, malware, or other malicious code in the
                Site or any component of the Services;
              </li>
              <li>
                Modification, reverse-engineering, or other manipulation of the
                Site or any component of the Services; and
              </li>
              <li>
                Interfering with, or disrupting, the Site or any component of
                the Services.
              </li>
            </ul>
            <p>
              In order to ensure the integrity of the Services, we reserve the
              right at any time in our sole discretion to block Users with
              certain unique device identifiers from accessing the Services.
            </p>
            <h2>CONSENT TO ELECTRONIC TRANSACTIONS AND DISCLOSURES</h2>
            <h3>Consent through the Use of the Service</h3>
            <p>
              Because the Service operates only on the Internet, whether you
              choose to participate in the Service, it is necessary for you to
              consent to transact business with us online and electronically. As
              part of doing business with us and our lending partners,
              therefore, we also need you to consent to our giving you certain
              disclosures electronically, either via the Service or to the email
              address you provide to us. By agreeing to the Terms, you agree to
              receive electronically all documents, communications, notices,
              contracts, and agreements arising from or relating to your use of
              the Service (each a “Disclosure”), from us. The decision to do
              business with us electronically is yours. This document informs
              you of your rights concerning Disclosures.
            </p>
            <h3>Consent to Receive Electronic Communications</h3>
            <p>
              Any Disclosures will be provided to you electronically through the
              Service or via electronic mail to the email address you provided.
              We may discontinue the electronic provision of Disclosures at any
              time in our sole discretion.
            </p>
            <p>
              Your consent to receive Disclosures and transact business
              electronically, and our agreement to do so, applies to any
              transactions to which such Disclosures relate, whether between you
              and Company, our partners, including Bank partner and its nonbank
              affiliates and partners.
            </p>
            <p>
              Your consent will remain in effect for so long as you are a User
              and, if you are no longer a User, will continue until such time as
              all Disclosures relevant to transactions that occurred while you
              were a User have been made.
            </p>
            <h2>MISCELLANEOUS TERMS</h2>
            <h3>Third-Party Sites, Content and Services</h3>
            <p>
              The Service may contain links to third-party Web sites
              (“Third-Party Sites”) or third-party content (“Third-Party
              Content”) and may provide third-party services (“Third-Party
              Services”), including payment processors and other payment
              intermediaries that you may use in connection with your use of the
              Services. You use Third-Party Sites, Third-Party Content
              (together, the “Third Party Materials”) or Third-Party Services at
              your own risk.
            </p>
            <p>
              Firecard.io Service makes no claim or representation regarding
              Third-Party Materials and Third-Party Services, and provides them
              or links to them only as a convenience. Inclusion in the Services
              of Third-Party Materials does not imply Firecard.io’ endorsement,
              adoption or sponsorship of, or affiliation with, such Third-Party
              Materials. Firecard.io accepts no responsibility for reviewing
              changes or updates to, or the quality, content, policies, nature
              or reliability of Third-Party Materials, or Third-Party Sites
              linking to the Services. When you leave the Services, our terms
              and policies no longer govern, and when you use Third-Party
              Services their terms and policies apply. You should review
              applicable terms and policies, including privacy and data
              gathering practices, of any Third-Party Materials and Third-Party
              Services, and should make whatever investigation you feel
              necessary or appropriate before proceeding with any transaction
              with any third party.
            </p>
            <p>
              The provider of Third-Party Materials and Third-Party Services is
              solely responsible for such Third-Party Materials and Third-Party
              Services, the content therein, any warranties to the extent that
              such warranties have not been disclaimed, any training, support or
              maintenance for the Third-Party Materials and Third-Party
              Services, and any claims that you or any other party may have
              relating to that Third-Party Materials and Third-Party Services or
              your use of them. You acknowledge that you are purchasing the
              license to Third-Party Materials and Third-Party Services from the
              provider of those Third-Party Materials and Third-Party Services;
              Firecard.io is acting as agent for the provider in providing such
              Third-Party Materials and Third-Party Services to you; Firecard.io
              is not a party to the license between you and the provider with
              respect to such Third-Party Materials and Third-Party Services;
              and Firecard.io is not responsible for such Third-Party Materials
              and Third-Party Services, the content therein, or any warranties
              or claims that you or any other party may have relating to such
              Third-Party Materials and Third-Party Services or your use of
              them.
            </p>
            <h3>Privacy</h3>
            <p>
              Please review the Service’s Privacy Policy on the Site
              https://firecard.io/.By using the Service, you are consenting to
              the Privacy Policy and agree to have your personal data
              transferred to and processed. We may listen to and record phone
              calls between you and our representatives without notice to you as
              permitted by applicable law.
            </p>
            <h3>Disclaimers of Warranties</h3>
            <p>
              Your access to and use of the Services is at your own risk. You
              understand and agree that the Services are provided to you on an
              “AS IS” and “AS AVAILABLE” basis. Without limiting the foregoing,
              OUR AFFILIATES AND WE DISCLAIM ANY WARRANTIES, EXPRESS OR IMPLIED,
              OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
              NONINFRINGEMENT.
            </p>
            <p>
              We make no warranty and disclaim all responsibility and liability
              for the completeness, accuracy, availability, timeliness, security
              or reliability of the Services or any content in the Site. We will
              not be responsible or liable for any harm to your computer system,
              loss of data, or other harm that results from your access to or
              use of the Services. Moreover, you agree that we do not have
              responsibility or liability for the deletion of, or the failure to
              store or to transmit, any material or content and other
              communications maintained on the Site. We make no warranty that
              the Services will meet your requirements or be available on an
              uninterrupted, secure, or error-free basis. No advice or
              information, whether oral or written, obtained from the Services
              or our representatives, will create any warranty not expressly
              made in these Terms.
            </p>
            <p>
              The Service may be temporarily unavailable from time to time for
              maintenance or other reasons. The Company is not responsible for
              any problems or technical malfunction of any telephone network or
              lines, computer online systems, servers or providers, computer
              equipment, software, failure of email or players on account of
              technical problems or traffic congestion on the Internet or on the
              Service, including injury or damage to Users or to any other
              person’s computer related to or resulting from participating or
              downloading materials in connection with the Service.
            </p>
            <p>
              Under no circumstances will the Company be responsible for any
              loss or damage, including any loss or damage to any User financial
              damages or lost profits, loss of business, or personal injury or
              death, resulting from anyone’s use of the Service, through the
              Service or transmitted to Users, or any interactions between Users
              of the Service, whether online or offline.
            </p>
            <p>
              The Company reserves the right to change any content contained on
              the Site at any time without notice. Reference to any products,
              services, processes or other information, by trade name,
              trademark, manufacturer, supplier or otherwise does not constitute
              or imply their endorsement, sponsorship or recommendation, or any
              affiliation with them, by the Company.
            </p>
            <h3>Limitation on Liability</h3>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR
              SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS
              AND LICENSORS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
              INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING
              LOSS OF PROFITS, LOSS OF DATA, LOSS OF USE, LOSS OF ANTICIPATED
              SAVINGS; ACCRUED BUT WASTED EXPENDITURE; COST OF PROCUREMENT OF
              SUBSTITUTE GOODS OR SERVICES, LOSS OF GOODWILL; OR OTHER
              INTANGIBLE LOSSES RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR
              INABILITY TO ACCESS OR USE THE SERVICES; (ii) ANY CONDUCT OR
              CONTENT OF ANY THIRD PARTY ON THE SITE, INCLUDING ANY DEFAMATORY,
              OFFENSIVE, OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES;
              (iii) ANY CONTENT OBTAINED FROM THE SERVICES; AND (iv)
              UNAUTHORIZED ACCESS, USE OR ALTERATION OF ANY MATERIAL OR CONTENT,
              WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE)
              OR ANY OTHER LEGAL THEORY, WHETHER OR NOT WE HAVE BEEN INFORMED OF
              THE POSSIBILITY OF SUCH DAMAGE. IN SOME JURISDICTIONS THE
              APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF
              LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
              LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, OUR
              LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY
              APPLICABLE LAW. IN ALL CASES OUR MAXIMUM LIABILITY IS LIMITED TO
              ANY FEES PAID BY YOU TO US.
            </p>
            <h3>Governing Law</h3>
            <p>
              By visiting or using the Service, you agree that the laws of the
              European Union, and Payment Processor Partners, regarding their
              terms of conditions, without regard to any principles of conflict
              of laws that would require or permit the application of the laws
              of any other jurisdiction, will govern these Terms.
            </p>
            <h3>Indemnity</h3>
            <p>
              You agree to indemnify and hold us harmless from any claim or
              demand, including attorney’s fees, made by any third party due to
              or arising out of your breach of these Terms, or your violation of
              any law or the rights of a third party. Nothing in these Terms
              shall be deemed to exclude or limit your liability in respect of
              any indemnity given by you under these Terms.
            </p>
            <h3>Waiver</h3>
            <p>
              The failure of the Company to exercise or enforce any right or
              provision of these Terms will not constitute a waiver of such
              right or provision in that or any other instance. If any provision
              of these Terms is held invalid, the remainder of these Terms will
              continue in full force and effect. If any provision of these Terms
              will be deemed unlawful, void or for any reason unenforceable,
              then that provision will be deemed severable from these Terms and
              will not affect the validity and enforceability of any remaining
              provisions.
            </p>
            <h3>Termination of the Services</h3>
            <p>
              You may terminate the Services at any time by indicating that you
              wish to terminate by contacting us at the e-mail address:
              support@firecard.io . We will treat a valid termination request
              from you through the Site as effective on the following business
              day. We also may, but are not required to, honour any termination
              request that you provide through other means. If we do so, then we
              may treat the termination request as effective up to 10 business
              days after receipt. When your termination request is effective, we
              will cancel any requests for pending payments, but we are not
              required to stop any payments that are already in progress. You
              will remain responsible for any amounts you owe us under this
              Agreement. We may terminate your access to the Services and/or
              this Agreement at any time and for any reason without notice
              unless required otherwise by law.
            </p>
            <h3>Contact</h3>
            <p>
              The Services are provided, and the Site is operated and provided
              by the EU company corporation. If you have any questions about
              these Terms, please contact us at support@firecard.io
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Terms;
