import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from 'constants/ThemeConstant';
import { API_ENDPOINT_URL } from 'constants/ApiConstant';

export const APP_NAME = 'Firecard.io';
export let API_BASE_URL = API_ENDPOINT_URL;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

const persistTheme = JSON.parse(localStorage.getItem('persist:root'));
let themeUserConfig;
let sideNavUserConfig;
let navTypeUserConfig;

if (persistTheme) {
  const saveUserTheme = JSON.parse(persistTheme?.theme);

  themeUserConfig = saveUserTheme.currentTheme;
  sideNavUserConfig = saveUserTheme.sideNavTheme;
  navTypeUserConfig = saveUserTheme.navCollapsed;
}

export const THEME_CONFIG = {
  navCollapsed: persistTheme ? navTypeUserConfig : false,
  sideNavTheme: persistTheme ? sideNavUserConfig : SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: persistTheme ? themeUserConfig : 'light',
  direction: DIR_LTR,
};
