import { GET_ID } from '../constants/ID';

const initState = '';

const ID = (state = initState, action) => {
  switch (action.type) {
    case GET_ID:
      return (state = action.payload);
    default: {
      return state;
    }
  }
};
export default ID