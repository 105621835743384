import { SAVE_USER_TO_STORE, IS_CONFIRM_USER } from "../constants/User";

export const saveUserToStore = (user) => {
  return {
     type: SAVE_USER_TO_STORE,
      user
     };
};

export const confirmUser = (user) => {
  return {
     type: IS_CONFIRM_USER,
      user
     };
};