import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register`} component={lazy(() => import(`./authentication/register-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/contact-us`} component={lazy(() => import(`./authentication/contact-us`))} />
        <Route path={`${AUTH_PREFIX_PATH}/forgot-password`} component={lazy(() => import(`./authentication/forgot-password`))} />
        <Route path={`${AUTH_PREFIX_PATH}/reset-password`} component={lazy(() => import(`./authentication/reset-password`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-2`} component={lazy(() => import(`./errors/error-page-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/confirm-email`} component={lazy(() => import(`./authentication/confirm-email`))} />
        <Route path={`${AUTH_PREFIX_PATH}/not-confirm-email`} component={lazy(() => import(`./information-page/not-confirmed-email`))} />
        <Route path={`${AUTH_PREFIX_PATH}/thankyou-page-1`} component={lazy(() => import(`./thankyou-page/thankyou-page-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/thankyou-page-2`} component={lazy(() => import(`./thankyou-page/thankyou-page-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/thankyou-page-3`} component={lazy(() => import(`./thankyou-page/thankyou-page-3`))} />
        <Route path={`${AUTH_PREFIX_PATH}/terms-and-privacy`} component={lazy(() => import(`./information-page/terms-confirmation`))} />
        <Route path={`${AUTH_PREFIX_PATH}/deactiveted-user`} component={lazy(() => import(`./information-page/deactivated-user`))} />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;

