import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNIN_SUCCESS,
  SIGNUP,
} from '../constants/Auth';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';

const initState = {
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        redirect: `${APP_PREFIX_PATH}/accounts`,
        token: action.token,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: '/',
      };
    }
    case SIGNIN_SUCCESS: {
      return {
        ...state,
        token: action.token,
      };
    }
    case SIGNUP: {
      return {
        ...state,
        redirect: `${AUTH_PREFIX_PATH}/login`,
      };
    }
    default:
      return state;
  }
};

export default auth;
