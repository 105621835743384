import fetch from 'FetchInterseptor/general/FetchInterceptor';

const accountService = {};

accountService.getAccounts = function (params) {
  return fetch({
    url: `/accounts?${params}`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
  });
};

accountService.getAccountsById = function (params) {
  return fetch({
    url: `/accounts/${params}`,
    method: 'get',
    headers: {
      'public-request': 'false',
    },
  });
};

accountService.createAccounts = function (data) {
  return fetch({
    url: `/accounts`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data
  });
};
accountService.updateAccounts = function (id,data) {
  return fetch({
    url: `/accounts/${id}`,
    method: 'put',
    headers: {
      'public-request': 'false',
    },
    data
  });
};
accountService.deleteAccounts = function (id) {
  return fetch({
    url: `/accounts/${id}`,
    method: 'delete',
    headers: {
      'public-request': 'false',
    },
    
  });
};

accountService.closeAccountsById = function (id) {
  return fetch({
    url: `/accounts/${id}/close`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    
  });
};

accountService.updateAccountsLimits = function (id,data) {
  return fetch({
    url: `/accounts/${id}/limits`,
    method: 'put',
    headers: {
      'public-request': 'false',
    },
    data
  });
};
accountService.updateAccountsPricingPlan = function (id,data) {
  return fetch({
    url: `/accounts/${id}/pricing-plan`,
    method: 'put',
    headers: {
      'public-request': 'false',
    },
    data
  });
};

accountService.requestToUpBalance = function (id,data) {
  return fetch({
    url: `/accounts/${id}/request-to-up-balance`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data
  });
};

accountService.topUpBalance = function (id,data) {
  return fetch({
    url: `/accounts/${id}/top-up-balance`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data
  });
};
accountService.balanceTransfer = function (id,data) {
  return fetch({
    url: `/accounts/${id}/balance-transfer`,
    method: 'post',
    headers: {
      'public-request': 'false',
    },
    data
  });
};
export default accountService;
