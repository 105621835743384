import {
  GET_ACCOUNT_LIST,
  FETCH_ACCOUNTS_LIST,
  GET_SUBACCOUNTS_LIST,
  FETCH_SUBACCOUNTS_LIST,
  CLEAR_SUBACCOUNTS_LIST
} from '../constants/Accounts';

export const getAccountList = (accounts) => {
  return {
    type: GET_ACCOUNT_LIST,
    accounts,
  };
};

export const fetchAccountsList = (payload) => {
  return {
    type: FETCH_ACCOUNTS_LIST,
    payload,
  };
};

export const fetchSubaccountsList = (payload) => {
  return {
    type: FETCH_SUBACCOUNTS_LIST,
    payload,
  };
};

export const getSubaccountList = (subaccounts) => {
  return {
    type: GET_SUBACCOUNTS_LIST,
    subaccounts,
  };
};

export const clearSubaccountList = () => {
  return {
    type: CLEAR_SUBACCOUNTS_LIST,
  };
};