import { GET_COMPANY_LIST } from '../constants/Company';

const initialState = {
  companies: [],
};

const company = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_LIST: {
      return { ...state, companies: action.companiesList };
    }
    default:
      return state;
  }
};

export default company;
