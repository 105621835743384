import Icon, {
  DashboardOutlined,
  ApiOutlined,
  TeamOutlined,
  CreditCardOutlined,
  ProjectOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { AccountIcon } from 'assets/svg/icon';

const IconAccount = (props) => <Icon component={AccountIcon} {...props} />;

const dashBoardNavTree = [
  {
    key: 'dashboard',
    path: `${APP_PREFIX_PATH}/dashboard`,
    nonActive: 'Coming soon',
    title: 'sidenav.dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const pricingPlanNavTree = [
  {
    key: 'pricingPlan',
    path: `${APP_PREFIX_PATH}/pricing-plan`,
    nonActive: 'Coming soon',
    title: 'sidenav.pricingPlan',
    icon: TagOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const userNavTree = [
  {
    key: 'User',
    path: `${APP_PREFIX_PATH}/users/user-list`,
    title: 'sidenav.user',
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const companiesNavTree = [
  {
    key: 'Companies',
    path: `${APP_PREFIX_PATH}/companies/companies-list`,
    title: 'sidenav.companies',
    icon: ApiOutlined,
    breadcrumb: false,
    submenu: [],
    roles: ['ROLE_SUPER_ADMIN'],
  },
];

const statementNavTree = [
  {
    key: 'statement',
    path: `${APP_PREFIX_PATH}/statement`,
    title: 'sidenav.statement',
    icon: ProjectOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const accountsNavTree = [
  {
    key: 'Accounts',
    path: `${APP_PREFIX_PATH}/accounts/accounts-list`,
    title: 'sidenav.accounts',
    icon: IconAccount,
    breadcrumb: false,
    submenu: [],
  },
];

const cardsNavTree = [
  {
    key: 'Cards',
    path: `${APP_PREFIX_PATH}/cards/cards-list`,
    title: 'sidenav.cards',
    icon: CreditCardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];


export const navigationSuperAdminConfig   = [
  ...companiesNavTree,
  ...accountsNavTree,
  ...statementNavTree,
  ...cardsNavTree,
  ...userNavTree,
  ...dashBoardNavTree,
  ...pricingPlanNavTree,
]
export const navigationAdminConfig   = [
  ...accountsNavTree,
  ...statementNavTree,
  ...cardsNavTree,
  ...userNavTree,
  ...dashBoardNavTree,
  ...pricingPlanNavTree,
]
export const navigationUserConfig   = [
  ...accountsNavTree,
  ...cardsNavTree,
  ...userNavTree,
  ...dashBoardNavTree,
  ...pricingPlanNavTree,
]
