import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import LandingPage from './landing/Main';
import { connect } from 'react-redux';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const LandingViews = ({ token }) => {
  return (
    <div className='landing-container'>
      <Suspense fallback={<Loading cover='page' />}>
        <Switch>
          {token ? (
            <Redirect from='/' to={`${APP_PREFIX_PATH}`}></Redirect>
          ) : (
            <Route path='/' component={LandingPage} />
          )}
        </Switch>
      </Suspense>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default connect(mapStateToProps)(LandingViews);
