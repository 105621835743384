import fetch from "FetchInterseptor/auth/FetchInterceptor";
import { API_AUTHORIZATION_TOKEN } from "../constants/ApiConstant";

const JwtAuthService = {};

JwtAuthService.signIn = function (data) {
  return fetch({
    url: "/auth/sign-in",
    method: "post",
    headers: {
      "public-request": "true",
      Authorization: `Basic ${API_AUTHORIZATION_TOKEN}`,
    },
    data: data,
  });
};

export default JwtAuthService;
