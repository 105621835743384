import { SAVE_USER_TO_STORE, IS_CONFIRM_USER } from '../constants/User';

const initialState = {
  firstName: '',
  lastName: '',
  role: '',
  id: '',
  email: '',
  isRequiredConfirm: false
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USER_TO_STORE: {
      return {
        ...state,
        firstName: action.user.firstName,
        lastName: action.user.lastName,
        role: action.user.role,
        id: action.user.id,
        email: action.user.email,
      };
    }
    case IS_CONFIRM_USER:{
      return {
        ...state,
        isRequiredConfirm:action.user.privacyPolicyConfirmed
      }
    }
    default:
      return state;
  }
};

export default user;
