import {
  HIDE_MESSAGE,
  SHOW_LOADING,
  SHOW_MESSAGE,
  HIDE_LOADING,
} from "../constants/Info";

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};
export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
  };
};
