import { GET_PRICINGPLAN_LIST } from '../constants/PricingPlan';

const initialState = {
  pricingPlan: [],
};

const pricingPlans = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRICINGPLAN_LIST: {
      return {
        ...state,
        pricingPlan: action.pricingPlan,
      };
    }
    default:
      return state;
  }
};

export default pricingPlans;
